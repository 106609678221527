import { Card } from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import Link from "next/link";
import { FaUserAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../../pages/blog/[...post]";
export default function BlogCard({ post, isFromHomePage = false, source = "", user = { has_paid: false } }) {
  const [showActivateMembership, setShowActivateMembership] = useState(false);

  useEffect(() => {
    setShowActivateMembership(!user?.has_paid && post.fields?.paid === true);
  }, [post.fields?.paid, user?.has_paid]);

  const blogLink = showActivateMembership ? "/welcome" : `/blog${post.fields.postUrl}${source ? "?s=" + source : ""}`;

  return (
    <Card className="blog-card" style={{ height: isFromHomePage === "blog" ? "100%" : "auto", width: "auto" }}>
      <Link href={blogLink}>
        <Card.Img variant="top" src={`https:${post.fields.postImage.fields.file.url}`} />
      </Link>
      {post.fields?.paid && (
        <Link className="text-orange fw-9 ml-3 mt-3 d-block" href={`/welcome-content-monthly`}>
          ⭐ Premium Content
        </Link>
      )}
      <Card.Body className="pt-0 mt-3">
        <Link className="text-black is-link" href={blogLink}>
          <Card.Title>{post.fields.title}</Card.Title>
        </Link>
        <div
          className="d-flex"
          style={{
            fontSize: "12px",
            color: "#6B6C72",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span>
            <MdDateRange /> {post.fields.published.split("T")[0]}{" "}
          </span>
          <span>
            <Link href={`/author/${post.fields.blogAuthor.fields.name}`}>
              <FaUserAlt /> {post.fields.blogAuthor.fields.name}
            </Link>
          </span>
        </div>
        <div className="card-text" style={{ fontSize: ".9rem" }}>
          {post.fields?.articlePreview
            ? documentToReactComponents(post.fields?.articlePreview || "", renderOptions)
            : post.fields.metaDescription.split(" ").slice(0, 50).join(" ") + "..."}
        </div>
        {showActivateMembership ? (
          <h5 className="text-center mt-5">
            <Link href={blogLink}>🔒 Activate Membership unlock</Link>
          </h5>
        ) : (
          <Link className="btn btn-orange" href={blogLink}>
            Read More
          </Link>
        )}
      </Card.Body>
    </Card>
  );
}
