import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import client from "../../components/contentful/Contentful";
import { useRouter } from "next/router";
import { Card } from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Head from "next/head";
import BlogCard from "../../components/contentfulBlogs/BlogCard";
import { validateEmail } from "../../utils/Utils";
import Input from "../../components/form/Input";
import { AiOutlineMail } from "react-icons/ai";
import Link from "next/link";
import { appServerSideProps } from "../../utils/getAppService";
import Utils from "../../utils/Utils";

export const renderOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "blogPost") {
        return <a href={`/blog/${node.data.target.fields.slug}`}> {node.data.target.fields.title}</a>;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "codeBlock") {
        return (
          <pre>
            <code>{node.data.target.fields.code}</code>
          </pre>
        );
      }

      if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
        return (
          <iframe
            src={node.data.target.fields.embedUrl}
            height="100%"
            width="100%"
            frameBorder="0"
            scrolling="no"
            title={node.data.target.fields.title}
            allowFullScreen={true}
          />
        );
      }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          height={"auto"}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
      );
    },
  },
};

export default function Post({ blog, blogs, user }) {
  const router = useRouter();
  const [formError, setFormError] = useState();
  const [email, setEmail] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [showActivateMembership, setShowActivateMembership] = useState(false);
  const isTrader = Utils.isTrader(user);

  useEffect(() => {
    setShowActivateMembership(!user?.has_paid && blog.fields?.paid === true);
  }, [blog.fields?.paid, user?.has_paid]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmitClick = async (e) => {
    setFormError();
    e.preventDefault();

    if (!email || email == "") {
      setFormError("Please enter an email");
      return;
    }
    if (!validateEmail(email)) {
      setFormError("Email is not valid");
      return;
    }
    fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/klaviyo/blog/${email}/save`)
      .then((res) => {
        if (res.status === 200) {
          setButtonDisabled(true);
          setFormSuccess("Request has been sent successfully!");
          setFormError("");
        } else {
          setFormError("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        console.error("err", err);
        setFormError("An error occurred. Please try again");
      });
  };
  // const { post } = router.query;
  // const [blogs, setBlogs] = useState([]);
  // const [blog, setBlog] = useState(null);
  // useEffect(() => {
  //   const getPost = async () => {
  //     const res = await client.getEntries({
  //       content_type: "blog",
  //       "fields.targetSite[in]": "ShotQualityBets",
  //       // "fields.postUrl": post.join("/"),
  //     });
  //     setBlogs(res.items);
  //     const postPath = "/" + post.join("/");
  //     const selectedBlog = res.items.find((item) => item.fields.postUrl === postPath);
  //     setBlog(selectedBlog);
  //   };
  //   getPost();
  // }, []);
  // useEffect(() => {
  //   if (blog) {
  //     window.history.replaceState(null, "ShotQualityBets | ", `/blog${blog.fields.postUrl}`);
  //   }
  // }, [blog]);
  if (!blog) return null;

  return (
    <>
      <Head>
        <title>{blog?.fields?.metaTitle}</title>
        <meta name="description" content={blog?.fields?.metaDescription} />
        <meta property="og:title" content={blog?.fields?.metaTitle?.substr(0, 59)} />
        <meta property="og:description" content={blog?.fields?.metaDescription?.substr(0, 159)} />
        <meta property="og:image" content={`https:${blog?.fields?.postImage?.fields?.file?.url}`} />
        <meta property="og:url" content={`https://shotqualitybets.com/blog${blog?.fields?.postUrl}`} />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ShotQualityBets" />
        <meta name="twitter:title" content={blog?.fields?.metaTitle?.substr(0, 59)} />
        <meta name="twitter:description" content={blog?.fields?.metaDescription?.substr(0, 159)} />
        <meta name="twitter:image" content={`https:${blog?.fields?.postImage?.fields?.file?.url}`} />

        {/* <meta name="twitter:creator" content="@SarahMaslinNir"/> */}
        {/* <meta name="twitter:card" content="summary" /> */}

        <link rel="me" href="https://twitter.com/@ShotQualityBets"></link>
      </Head>
      <MainLayout className="bg-white">
        <div className="container-fluid" style={{ overflow: "hidden", paddingBottom: "2rem" }}>
          <div className="row">
            <div className="col-md-9 col-sm-12" style={{ padding: "2rem" }}>
              {blog ? (
                <Card className="blog-card">
                  <Card.Img variant="top" src={`https:${blog.fields.postImage.fields.file.url}`} />
                  <Card.Body>
                    <Card.Title>{blog.fields.title}</Card.Title>
                    <div
                      className="d-flex"
                      style={{
                        fontSize: "14px",
                        color: "#6B6C72",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <span>
                        <MdDateRange /> {blog.fields.published.split("T")[0]}{" "}
                      </span>
                      <span>
                        <Link href={`/author/${blog.fields.blogAuthor.fields.name}`}>
                          <FaUserAlt /> {blog.fields.blogAuthor.fields.name}
                        </Link>
                      </span>
                    </div>
                    {showActivateMembership ? (
                      <>
                        <div className="card-text" style={{ fontSize: "1rem" }}>
                          {documentToReactComponents(blog.fields?.articlePreview || "", renderOptions)}
                        </div>
                        <h5 className="text-center mt-5 mb-5">
                          <Link href={"/welcome"}>🔒 Activate Membership unlock</Link>
                        </h5>
                      </>
                    ) : (
                      <div className="card-text">{documentToReactComponents(blog.fields.body, renderOptions)}</div>
                    )}
                  </Card.Body>
                </Card>
              ) : blog == undefined ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                  <div className=" text-primary" role="status">
                    <h4 className="visually-hidden">Oops! The post you were looking for is no longer available.</h4>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="row">
                {!isTrader && (
                  <div className="col-md-12 mt-5">
                    <div className="d-flex flex-column justify-content-center pr-1">
                      <h2 style={{ marginBottom: "5px" }}>GET FREE PICKS</h2>
                      <h4>STRAIGHT TO YOUR INBOX</h4>
                      <form action="#" className="pt-2">
                        <Input
                          style={{ borderRadius: "10px", minWidth: "90%" }}
                          icon={<AiOutlineMail />}
                          type="email"
                          placeholder="Enter your email to get access"
                          onChange={handleEmailChange}
                          value={email}
                        />
                        <button
                          style={{ margin: "0px", marginBottom: "1rem", padding: "9px 20px", minWidth: "50%" }}
                          className="btn btn-lg btn-primary btn-gradient"
                          onClick={onSubmitClick}
                          disabled={buttonDisabled}
                        >
                          Get Free Picks
                        </button>
                      </form>
                      <div className="position-relative w-100">
                        {formError ? <p className={"wrongDetails"}>{formError}</p> : <></>}
                        {formSuccess ? <p className={"formSuccess"}>{formSuccess}</p> : <></>}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="col-md-12 mt-5 d-flex"
                  style={{ height: "188vh", overflow: "auto", gap: "1rem", flexDirection: "column" }}
                >
                  {blogs?.length > 0 &&
                    blogs.map((item) =>
                      item.fields.postUrl === blog.fields.postUrl ? null : (
                        <BlogCard key={item.fields.postUrl} post={item} user={user} />
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}

// export async function getStaticPaths() {
//   const res = await client.getEntries({
//     content_type: "blog",
//     "fields.targetSite[in]": "ShotQualityBets",
//   });
//   const paths = res.items.map((item) => {
//     const postUrl = item.fields.postUrl.split("/").filter((item) => item !== "");
//     return {
//       params: { post: postUrl },
//     };
//   });
//   return {
//     paths,
//     fallback: true,
//   };
// }
// export async function getStaticProps({ params }) {
//   const res = await client.getEntries({
//     content_type: "blog",
//     "fields.targetSite[in]": "ShotQualityBets",
//   });
//   const postPath = "/" + params.post.join("/");
//   const selectedBlog = res.items.find((item) => item.fields.postUrl === postPath);

//   if (!selectedBlog) {
//     return {
//       redirect: {
//         destination: "/blog",
//         permanent: false,
//       },
//     };
//   }
//   return {
//     props: {
//       blog: selectedBlog,
//       blogs: res.items,
//     },
//     revalidate: 1,
//   };
// }

export const getServerSideProps = appServerSideProps(async ({ appService, cookies, params, query }) => {
  const res = await client.getEntries({
    content_type: "blog",
    "fields.targetSite[in]": "ShotQualityBets",
    order: "-fields.published",
  });
  const postPath = "/" + params.post.join("/");
  const selectedBlog = res.items.find((item) => item.fields.postUrl === postPath);
  let blogs = res.items;
  if (query.s) {
    // blogs = res.items.filter((it) => it.metadata.tags[0]?.sys.id.includes(query.s));
    blogs = res.items.filter((it) => JSON.stringify(it.metadata.tags).includes(query.s));
  }
  if (!selectedBlog) {
    return {
      redirect: {
        destination: "/blog",
        permanent: false,
      },
    };
  }
  const isUserLogged = appService.getUser();

  let user = null;

  if (isUserLogged) {
    user = await appService.get("api/account");
  }
  return {
    props: {
      blog: selectedBlog,
      blogs: blogs,
      user: user,
    },
  };
});
